$color-blue: #29aee0;
$color-blue-light: #6fe0ff;
$color-blue-baby: #ddf6ff;

$color-white: #fff;
$color-white-dark: darken($color-white, 10%);
$color-red: #ce4d5a;
$color-red-light: #ff5e6e;
$color-green: #5ea85b;
$color-green-light: lighten($color-green, 15%);
$color-green-dark:#006400;
$color-transparent: transparent;
$color-transparent-dark: rgb(200 200 200 / 20%);
$color-purple: #500068;

$color-black: #000;
$color-gray: #ccc;
$color-gray-light: #eee;
$color-gray-dark: #777;
$color-yellow:#fac928;
$color-yellow-light: lighten($color-yellow, 15%);
$color-amber:#fd6a02;
$color-yellow-green: #cc0;

@mixin mini {
  @media (width < 360px) and (max-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin miniContainer {
  @media (width < 360px) and (max-device-pixel-ratio: 2) {
    @content;
  }
}

@mixin small {
  @media (width < 600px) {
    @content;
  }
}


@mixin smallContainer {
  @media (width < 600px) {
    @content;
  }
}

@mixin medium {
  @media (width >= 600px) {
    @content;
  }
}

@mixin mediumContainer {
    @container (width >= 600px) {
        @content;
    }
}

@mixin large {
  @media (width >= 1280px) {
    @content;
  }
}

@mixin largeContainer {
  @container (width >= 1280px) {
    @content;
  }
}

@mixin huge {
    @media (width >= 1440px) {
        @content;
    }
}

@mixin hugeContainer {
    @container (width >= 1440px) {
        @content;
    }
}

@mixin colossal {
    @media (width >= 1920px) {
        @content;
    }
}

@mixin colossalContainer {
    @container (width >= 1920px) {
        @content;
    }
}

@mixin if-mouse {
  @media (any-pointer: fine) and (any-hover: hover) {
    @content;
  }
}

@mixin hover($selector: "") {
  @media (any-hover: hover) {
    @if $selector != "" {
      &:hover #{$selector} {
        @content;
      }
    }

    @else {
      &:hover {
        @content;
      }
    }
  }
}
